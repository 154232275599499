.gallery-header-image {
  width: 100%;
}

.image-list-tall-img {
  width: 100%;
}

.image-list-square-img {
  width: 100%;
}

.image-list-long-img {
  width: 100%;
}

.gallery-images .MuiImageListItemBar-root {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 4px;
}

@media only screen and (max-width: 600px) {
  .gallery-header-image {
    width: 85%;
  }
}
